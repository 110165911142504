import React from 'react';

const PastOrders: React.FC = () => {
    return (
        <div>
            <h1>Past Orders</h1>
            {/* Add your code for displaying past orders here */}
        </div>
    );
};

export default PastOrders;