import React from 'react';
import Header from '../components/header';

const TrackingScreen: React.FC = () => {
    return (
        <div>
            
        </div>
    );
};

export default TrackingScreen;